import React from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { EntryProps, PlainClientAPI, SysLink } from 'contentful-management';
import { findAsync } from '../../utilities';

interface PageSectionsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const PageSectionsField = ({ sdk, cma }: PageSectionsFieldProps) => {
  React.useEffect(() => {
    const unsubscribe: () => void = sdk.field.onValueChanged(
      async (sys: Array<SysLink>) => {
        const product = await findAsync(
          sys,
          async (item) =>
            await cma.entry.get({ entryId: item.sys.id }).then((e) => {
              if (e.sys.contentType.sys.id === 'product') return true;
              return false;
            })
        );

        if (product) {
          // TEMP: This demonstrates fetching a donation module but will need to be refactored to dynamically
          // choose a donation module based on a Products category + department.
          const donationModules: EntryProps[] | [] = await cma.entry
            .getMany({ query: { 'metadata.tags.sys.id[all]': 'donation' } })
            .then((r) => r.items || []);
          const existingValue: Array<SysLink> = sdk.field.getValue();
          const validModuleIds = new RegExp(
            donationModules.map((mod) => mod.sys.id).join('|')
          );
          const hasAnyDonationModule = existingValue.some((item) =>
            validModuleIds.test(item.sys.id)
          );

          if (!hasAnyDonationModule) {
            const relevantModule = donationModules.find((mod) =>
              mod.fields.title[sdk.locales.default].includes('Socks')
            );
            if (relevantModule) {
              sdk.field.setValue([
                ...existingValue,
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Entry',
                    id: relevantModule?.sys.id
                  }
                }
              ]);
            }
          }
        }
      }
    );

    sdk.window.startAutoResizer();

    return () => unsubscribe();
  });

  return (
    <MultipleEntryReferenceEditor
      viewType="link"
      sdk={sdk}
      hasCardEditActions={false}
      isInitiallyDisabled={false}
      parameters={{
        instance: { showCreateEntityAction: false, showLinkEntityAction: true }
      }}
    />
  );
};

export default PageSectionsField;
