import React from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI } from 'contentful-management';
import PageSections from './Fields/Page.sections';
import { Heading } from '@contentful/f36-components';

interface FieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}
interface IFieldMap {
  [k: string]: (_props: FieldProps) => JSX.Element;
}

/**
 * Mapping of contentTypeId.fieldId to the rendered component
 */
const FieldMap: IFieldMap = {
  'page.sections': PageSections
};

const Field = ({ sdk, cma }: FieldProps) => {
  const Component = FieldMap[`${sdk.contentType.sys.id}.${sdk.field.id}`];

  if (Component) {
    return <Component sdk={sdk} cma={cma} />;
  }
  return (
    <Heading>
      ERROR: Product app used in an unsupported field placement.
    </Heading>
  );
};

export default Field;
